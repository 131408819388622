import { AppPluginMeta } from '@grafana/data';

import { AppApi } from './AppApi';

const tagType = 'jsonData';

export interface AppJSONData {
  stackId: string;
  pdcClusterSlug: string;
  region: string;
  environment: '' | 'dev' | 'prod' | 'ops';
  [key: string]: string;
}

const AppMetaApi = AppApi.enhanceEndpoints({
  addTagTypes: [tagType],
}).injectEndpoints({
  endpoints: (build) => ({
    getAppMeta: build.query<AppPluginMeta<AppJSONData>, undefined>({
      query() {
        return {
          url: '/api/plugins/grafana-pdc-app/settings',
          method: 'GET',
        };
      },
      providesTags: [tagType],
    }),
  }),
});

export const { useGetAppMetaQuery } = AppMetaApi;
