import React, { useContext, useEffect, useState } from 'react';

import { DataSourceUpdatedSuccessfully } from '@grafana/data';
import { config, getAppEvents } from '@grafana/runtime';
import { Alert, LoadingPlaceholder } from '@grafana/ui';

import { PDC_CONN_MAX_LIFETIME_OVERRIDE_DATASOURCES, PDC_SQL_CONN_DEFAULT_MAX_LIFETIME_SECONDS } from 'consts';
import { PDCDataSourceJSONData, isNewFormStyling, useAppDispatch, useRudderStack } from 'feature/common';
import { AppContext } from 'feature/common/components/AppContext';
import { useDataSourceTest } from 'feature/datasource-config/hooks/useDataSourceTest';
import { PDCPluginExtensionDataSourceConfigContext } from 'feature/datasource-config/types';
import {
  DataSourceApi,
  allDataSourcesTagType,
  useGetPrivateNetworkDataSourceQuery,
  useIsDataSourceSupportedQuery,
} from 'feature/private-networks/api/DataSourceApi';
import { CreatePrivateNetworkModal } from 'feature/private-networks/components';
import { PrivateNetwork } from 'feature/private-networks/types';

import { DataSourceExtensionField } from './DataSourceExtensionField';
import { DataSourceExtensionTestingStatusError } from './DataSourceExtensionTestingStatusError';

interface Props {
  context: PDCPluginExtensionDataSourceConfigContext;
}
export const DataSourceExtensionConfig = ({
  context: { dataSource, setJsonData, dataSourceMeta, testingStatus },
}: Props) => {
  const {
    data: isDataSourceSupported,
    isLoading: isDataSourceSupportedLoading,
    error: isDataSourceSupportedError,
  } = useIsDataSourceSupportedQuery({
    pluginType: dataSource.type,
    pluginVersion: dataSourceMeta.info.version,
    grafanaVersion: config.buildInfo.version,
  });

  const [previousLifetime] = useState((dataSource.jsonData as PDCDataSourceJSONData).connMaxLifetime);
  const dispatch = useAppDispatch();
  const { trackRudderStackEvent } = useRudderStack();

  const newFormStyling = isNewFormStyling(dataSource.type);

  useEffect(() => {
    const appEvents = getAppEvents();
    const subscription = appEvents.subscribe(DataSourceUpdatedSuccessfully, () => {
      dispatch(DataSourceApi.util.invalidateTags([allDataSourcesTagType]));
    });

    return () => subscription.unsubscribe();
  }, [dispatch]);

  const { region } = useContext(AppContext);
  const { data: networkIdData, isLoading: isNetworkIdLoading } = useGetPrivateNetworkDataSourceQuery({
    dataSource,
    region,
  });

  const [value, setValue] = useState<string | undefined | null>();
  const networkID = networkIdData?.networkID;

  const [createNew, setCreateNew] = useState<boolean>(false);

  const [isTestingStatusError, setIsTestingStatusError] = useState<boolean>(false);

  useEffect(() => {
    if (testingStatus?.status === 'error') {
      setIsTestingStatusError(true);
    } else {
      setIsTestingStatusError(false);
    }
  }, [testingStatus]);

  useEffect(() => {
    if (value === undefined && networkID) {
      setValue(networkID);
    }
    setIsTestingStatusError(false);
  }, [networkID, value]);

  useDataSourceTest(value, dataSource.type, dataSource.uid);

  const onChange = (value: string | undefined) => {
    setValue(value);
    const data = {
      ...dataSource.jsonData,
      connMaxLifetime: previousLifetime,
      enableSecureSocksProxy: true,
      secureSocksProxyUsername: value,
    };
    if (PDC_CONN_MAX_LIFETIME_OVERRIDE_DATASOURCES.includes(dataSource.type)) {
      data.connMaxLifetime = PDC_SQL_CONN_DEFAULT_MAX_LIFETIME_SECONDS;
    }
    setJsonData(data);
    trackRudderStackEvent('grafana_pdc_plugin_data_source_extension_select_connection', {
      connection_id: value,
      data_source_type: dataSource.type,
      data_source_uid: dataSource.uid,
    });
  };

  const onCreate = () => {
    setCreateNew(true);
  };

  const onCreateClose = (privateNetwork?: PrivateNetwork) => {
    if (privateNetwork) {
      onChange(privateNetwork.id);
      trackRudderStackEvent('grafana_pdc_plugin_data_source_extension_create_connection', {
        connection_id: privateNetwork.id,
        data_source_type: dataSource.type,
        data_source_uid: dataSource.uid,
      });
    }
    setCreateNew(false);
  };

  const onClear = () => {
    trackRudderStackEvent('grafana_pdc_plugin_data_source_extension_clear_selected_connection', {
      connection_id: value,
      data_source_type: dataSource.type,
      data_source_uid: dataSource.uid,
    });
    setValue(null);
    setJsonData({
      ...dataSource.jsonData,
      connMaxLifetime: previousLifetime,
      secureSocksProxyUsername: undefined,
      enableSecureSocksProxy: false,
    });
  };

  if (isDataSourceSupportedLoading) {
    // we don't display anything as we don't want to show a loader and then nothing in case the data source is not supported
    return <LoadingPlaceholder text="Loading PDC config section" />;
  }

  if (isDataSourceSupportedError) {
    return <Alert severity="warning" title="Error when loading PDC config section" />;
  }

  return (
    <>
      {isDataSourceSupported && (
        <>
          <DataSourceExtensionField
            isLoading={isNetworkIdLoading}
            value={value}
            onChange={onChange}
            onNew={onCreate}
            onClear={onClear}
            newFormStyling={newFormStyling}
          />
          <CreatePrivateNetworkModal isOpen={createNew} onClose={onCreateClose} />
          {value && isTestingStatusError && <DataSourceExtensionTestingStatusError testingStatus={testingStatus!} />}
        </>
      )}
    </>
  );
};
