import { AppPlugin, PluginExtensionDataSourceConfigContext } from '@grafana/data';
import { getWebInstrumentations } from '@grafana/faro-web-sdk';

import { PRIVATE_NETWORKS_PATH } from 'consts';
import { AppJSONData } from 'feature/common/api/AppMetaApi';
import { FARO_ENV, initFaro } from 'feature/common/utils/faro';

// @ts-ignore
import { name, version } from '../package.json'; // eslint-disable-line no-restricted-imports

import { App } from './feature/common/components/App';
import { DataSourceExtension } from './feature/datasource-config/components/DataSourceExtension';

initFaro({
  version,
  paths: [PRIVATE_NETWORKS_PATH],
  getUrl: (environment) => {
    switch (environment) {
      case FARO_ENV.DEV:
        return 'https://faro-collector-prod-us-central-0.grafana.net/collect/1bbded1e7fd5372f574bdb75ffad3930';
      case FARO_ENV.STAGING:
        return 'https://faro-collector-prod-us-central-0.grafana.net/collect/7e621c6b8b3a31c907b0fa165c8d25ca';
      case FARO_ENV.PROD:
      default:
        return 'https://faro-collector-prod-us-central-0.grafana.net/collect/63f0c68e559ee2063855baafd2a0be7c';
    }
  },
  faroConfig: {
    instrumentations: getWebInstrumentations(),
  },
  pluginName: name,
});

export const plugin = new AppPlugin<AppJSONData>().setRootPage(App);

plugin.configureExtensionComponent &&
  plugin.configureExtensionComponent<PluginExtensionDataSourceConfigContext>({
    title: 'PDC Datasource Config',
    description: 'Private data source connection settings',
    extensionPointId: 'grafana/datasources/config',
    // typing DataSourceExtension as any until 10.2.0 is released
    // https://github.com/grafana/grafana/pull/75019#issuecomment-1724997540
    component: DataSourceExtension as any,
  });
