import React from 'react';

import { Alert } from '@grafana/ui';

import { useGetAppMetaQuery } from 'feature/common';
import { AppContext } from 'feature/common/components/AppContext';

export function DataSourceExtensionContextProvider({ children }: { children: JSX.Element }): JSX.Element {
  const { data, isSuccess, error } = useGetAppMetaQuery(undefined);
  if (error) {
    return <Alert severity="warning" title="Error when loading PDC config section" />;
  }
  return (
    <>{isSuccess && data?.jsonData && <AppContext.Provider value={data.jsonData}>{children}</AppContext.Provider>}</>
  );
}
